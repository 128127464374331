import { PanelPlugin } from '@grafana/data';
import { SimpleOptions } from './types';
import { SimplePanel } from './SimplePanel';

export const plugin = new PanelPlugin<SimpleOptions>(SimplePanel).setPanelOptions(builder => {
  return builder
  .addSliderInput({
    path: 'deleteTime',
    name: 'Delete After (minutes)',
    description: 'how many minutes to wait before deleteing an alert',
    defaultValue: 5,
    settings: {
      min: 1,
      max: 120,
      step: 1,
    },
  }).addSliderInput({
    path: 'minVoltageRange',
    name: 'Minimum Voltage Range',
    defaultValue: 5,
    settings: {
      min: 1,
      max: 24,
      step: 1,
    },
  }).addSliderInput({
    path: 'maxVoltageRange',
    name: 'Maximum Voltage Range',
    defaultValue: 10,
    settings: {
      min: 1,
      max: 24,
      step: 1,
    },
  })
    .addBooleanSwitch({
      path: 'showSeriesCount',
      name: 'Show series counter',
      defaultValue: false,
    })
    
});
